import { Grid, makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import ContactForm from '../components/contactForm';
import Layout from '../components/layout';
import SEO from '../components/seo';
import content from '../content/appointment/page.json';
import Colors from '../styles/colors';
import { defaultWidth, narrowWidth } from '../styles/dimensions';

const Appointment = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={content.title} />

			<section className={classes.section}>
				<h1 className={classes.header}>{content.title}</h1>
				<Grid container>
					<Grid item lg={6} className={classes.contactColumn}>
						<ContactForm />
					</Grid>
					<Grid item lg={6} className={classes.imageCol}>
						<StaticImage
							src="../images/appointment/Probetraining-vereinbaren.jpg"
							alt={content.image.alt}
							className={classes.image}
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</Grid>
				</Grid>
			</section>
		</Layout>
	);
};

export default Appointment;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultWidth,
		margin: '0 auto',
		paddingTop: '6.75em',
		minHeight: 'calc(100vh - 5.25em - 6.25em)',
		boxSizing: 'border-box',
		'@media(max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 1279px)': {
			paddingBottom: '4.25em',
			paddingTop: '3.25em',
			maxWidth: narrowWidth,
		},
	},
	header: {
		color: Colors.textBlack,
		marginTop: 0,
		marginBottom: '1.2em',
		'@media (max-width: 1279px)': {
			marginBottom: '0.7em',
		},
	},
	image: {
		maxWidth: '100%',
		objectPosition: 'center 10%',
		'@media (max-width: 1279px)': {
			display: 'block',
			margin: '0 auto',
		},
	},
	imageCol: {
		'@media (max-width: 1279px)': {
			order: 1,
			marginBottom: '2.5em',
			width: '100%',
		},
		'@media (min-width: 1280px)': {
			paddingLeft: '8.33333333%',
			position: 'relative',
			top: '-6.75em',
		},
	},
	contactColumn: {
		'@media (max-width: 1279px)': {
			order: 2,
		},
	},
});
